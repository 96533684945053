import { Button, Grid } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import BIcon from "../Icons/IconComponent";
import { ICONS } from "../Icons/IconComponentMapping";
import { signUpRequest, signInRequest } from "../../data/authConfig";

import "./../../styles/App.css";
import { generateID } from "../utilityFunctions";

const GeneralLoginComponent = () => {
  const { instance } = useMsal();

  const handleSignInRedirect = () => {
    const request = { redirectUri: location.href, ...signInRequest };
    instance.loginRedirect(request).catch(error => console.log(error));
  };

  const handleSignupRedirect = () => {
    const request = { redirectUri: location.href, ...signInRequest };
    instance.loginRedirect(request).catch(error => console.log(error));
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px",
        overflow: "hidden",
      }}
    >
      <Grid
        item
        mobile={12}
        laptop={6}
        style={{ background: "white", minWidth: "50px", maxWidth: "470px" }}
      >
        <Grid container spacing={0} style={{ padding: "26px" }}>
          <Grid
            item
            mobile={12}
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "26px",
            }}
          >
            <BIcon
              style={{ width: "98%", height: "auto" }}
              icon={ICONS["CompanyLogo"]}
            />
          </Grid>
          <Grid
            item
            mobile={12}
            id={generateID("txt", "landing", "header-desc")}
            className="landing-desc"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
              textAlign: "left",
            }}
          >
            <br />
            Please sign in your patient secure account to view personal data.
            <br />
            <br />
            Don't have an account yet, sign up for one.
          </Grid>
          <Grid
            item
            mobile={12}
            style={{
              marginTop: "90px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              id={generateID("btn", "landing", "signin")}
              variant="outlined"
              sx={{ width: "100%" }}
              className="baxi-button baxi-primary-button"
              onClick={() => {
                handleSignInRedirect();
              }}
            >
              Sign In
            </Button>
          </Grid>

          <Grid
            item
            mobile={12}
            style={{
              marginTop: "8px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              id={generateID("btn", "landing", "signup")}
              variant="outlined"
              sx={{ width: "100%", textDecoration: "underline" }}
              className="baxi-outlined-button"
              onClick={() => {
                handleSignupRedirect();
              }}
            >
              Sign Up
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GeneralLoginComponent;
