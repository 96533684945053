import { Box, Button, Grid } from "@mui/material";
import BIcon from "./Icons/IconComponent";
import { useNavigate } from "react-router-dom";
import { generateID } from "./utilityFunctions";
import IconPushChestButton from "./Icons/SVGs/IconPushChestButton";
import { APP_ROUTES } from "../constants/appRoutes";
import IconLeftArrow from "./Icons/SVGs/IconLeftArrow";
import { DeviceStatus, IDeviceDetails } from "./shared/DeviceDetailsContext";
import { useContext, useEffect } from "react";
import { PATCH_STATUS } from "../constants/patchStatus";
import { PATCH_ACTIVATION_STATUS } from "../constants/patchActivationStatus";

const ChestPressButton = () => {

  const deviceDetails = useContext<IDeviceDetails>(DeviceStatus);
  const navigate = useNavigate();

  /**
   * Check current patch state on page load.
   * It's possible the device state has changed and identifying symptoms is not allowed (e.g. device is offline).
   */
  useEffect(() => {
    if (
      deviceDetails.currentPatchState === PATCH_STATUS.RETURN_DEVICE ||
      deviceDetails.currentPatchState === PATCH_STATUS.NO_ACTIVE_DEVICE ||
      deviceDetails.currentPatchState === PATCH_STATUS.PENDING_TRANSMISSION ||
      deviceDetails.activationStatus === PATCH_ACTIVATION_STATUS.Deactivated
    ) {
      navigate(
        `${APP_ROUTES.ROOT}`
      );
    }
  })

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      style={{
        display: "flex",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px",
        overflow: "hidden",
      }}
    >
      <Grid
        item
        style={{
          background: "white",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <Grid item className="sticky-header">
          <Button style={{ paddingTop: "24px" }} onClick={() => navigate(-1)}>
            <BIcon
              icon={IconLeftArrow}
              style={{ height: "24px", width: "24px" }}
            />
          </Button>
          <Grid item padding="24px 20px 0 20px" display="flex">
            <Box className="rec-symp-step-counter">1</Box>
            <Box className="rec-symp-step-title">Press the button</Box>
          </Grid>
        </Grid>

        <Grid item padding="0 20px 0 20px" className="home-welcome-desc">
          <div className="desc">
            As a first step please make sure you press the button on your CAM
            DxT Patch.
          </div>
        </Grid>
        <Grid item style={{ display: "flex", justifyContent: "center" }}>
          <BIcon
            icon={IconPushChestButton}
            style={{
              verticalAlign: "bottom",
              width: "auto",
              height: "200px",
            }}
          />
        </Grid>
        <Grid
          item
          className="baxi-button-wrapper"
          style={{ display: "flex" }}
          sx={{
            paddingLeft: { mobile: "0px", laptop: "20px" },
            justifyContent: { mobile: "center", laptop: "inherit" },
          }}
        >
          <Button
            id={generateID("btn", "home", "identify-symptoms")}
            variant="outlined"
            sx={{ width: "80%" }}
            className="baxi-button baxi-primary-button"
            onClick={() =>
              navigate(`${APP_ROUTES.ROOT}${APP_ROUTES.PATIENT_SYMPTOMS}`)
            }
          >
            I have pressed the button
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ChestPressButton;
