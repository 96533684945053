import { Button, Grid } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { signInRequest } from "../../data/authConfig";

import "./../../styles/App.css";
import { generateID } from "../utilityFunctions";
import DeviceWearTime from "../shared/DeviceWearTimeComponent";
import { useContext } from "react";
import { DeviceStatus, IDeviceDetails } from "../shared/DeviceDetailsContext";
import { BaxiProgressScale } from "../shared/BaxiProgressComponent/BaxiProgressComponent";
import { PATCH_ACTIVATION_STATUS } from "../../constants/patchActivationStatus";

const MyInfoLoginComponent = () => {
  const deviceDetails = useContext<IDeviceDetails>(DeviceStatus)

  const { instance } = useMsal();

  const handleSignInRedirect = () => {
    const request = { redirectUri: location.href, ...signInRequest}
    instance.loginRedirect(request).catch(error => console.log(error));
  };

  const handleSignupRedirect = () => {
    const request = { redirectUri: location.href, ...signInRequest}
    instance.loginRedirect(request).catch(error => console.log(error));
  };

  const getRecordingStartedAt = () => {
    if (deviceDetails.activationStatus === PATCH_ACTIVATION_STATUS.Deactivated) {
      return Number.NEGATIVE_INFINITY; 
    } else {
      return +deviceDetails.recordingStartedAt;
    }
  }

  return (
    <Grid
    container
    spacing={0}
    direction="column"
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "10px",
      overflow: "hidden",
      height: "100%"
    }}
  >
      <Grid
        container
        mobile={12}
        laptop={6}
      >
        <Grid
          container
          spacing={0}
          sx={{ padding: "26px", border: "none" }}
        >
          <Grid
            item
            mobile={12}
            sx={{
              display: "flex",
              justifyContent: "center"
            }}
          >
            <DeviceWearTime
              size={BaxiProgressScale.LARGE}
              prescribedWearTimeDays={deviceDetails.prescribedWearTimeDays}
              recordingStartedAt={getRecordingStartedAt()}
            />
          </Grid>
          <Grid
            item
            mobile={12}
            id={generateID("txt", "landing", "header-desc")}
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "32px",
            }}
          >
            Sign In or Sign Up to view personal data.
          </Grid>
          <Grid
            item
            padding={0}
            mobile={12}
            sx={{
              marginTop: "8px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              id={generateID("btn", "landing", "signin")}
              variant="outlined"
              sx={{width: "100%", marginTop: "32px"}}
              className="baxi-button baxi-primary-button"
              onClick={() => {
                handleSignInRedirect();
              }}
            >
              Sign In
          </Button>
          </Grid>

          <Grid
            item
            mobile={12}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
          <Button
            id={generateID("btn", "landing", "signup")}
            variant="outlined"
            sx={{width: "100%", textDecoration: "underline", marginTop: "8px"}}
            className="baxi-outlined-button"
            onClick={() => handleSignupRedirect()}
          >
            SIGN UP if you don't have account
          </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MyInfoLoginComponent;
