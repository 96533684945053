import { CircularProgress } from "@mui/material";
import BIcon from "./Icons/IconComponent";
import IconNoInternet from "./Icons/SVGs/IconNoInternet";

const OfflineComponent = () => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div style={{ padding: "0 50px" }}>
            <p style={{ marginTop: "32px" }}>
              <b>WARNING:</b> Your <b>CAM DxT</b> is not connected to the
              internet.
            </p>
            <p>Please tap the red error message above to fix.</p>
            <BIcon
              icon={IconNoInternet}
              style={{
                height: "200px",
                minWidth: "100%",
                width: "100%",
                marginBottom: "-50px",
                marginTop: "24px",
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "48px",
              }}
            >
              <CircularProgress
                size={15}
                thickness={5}
                style={{ marginRight: "10px", color: "#1a1c1c" }}
              />
              <p
                style={{
                  textAlign: "center",
                  color: "#1a1c1c",
                }}
              >
                Trying to reconnect...
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OfflineComponent;
