import { useContext, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { M_GET_SYMPTOMS, M_RECORD_SYMPTOMS } from "../data/queries/symptoms";
import { Box, Button, CircularProgress, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ICONS } from "./Icons/IconComponentMapping";
import BIcon from "./Icons/IconComponent";
import DeviceDetails, { IPatientDetails } from "../types/IPatientDetails";
import messages from "./../data/UserMessages.json";
import { APP_ROUTES } from "../constants/appRoutes";
import IconLeftArrow from "./Icons/SVGs/IconLeftArrow";
import { generateID } from "./utilityFunctions";
import { DeviceStatus, IDeviceDetails } from "./shared/DeviceDetailsContext";
import { PATCH_STATUS } from "../constants/patchStatus";
import { PATCH_ACTIVATION_STATUS } from "../constants/patchActivationStatus";

const PatientSymptoms = () => {
  const device = useContext<IPatientDetails>(DeviceDetails);
  const deviceDetails = useContext<IDeviceDetails>(DeviceStatus);
  
  const navigate = useNavigate();

  const [data, gettingSymptomsInProgress] = useMutation(M_GET_SYMPTOMS);
  const [apiRecordSymptoms, recordingSymptomsInProgress] = useMutation(M_RECORD_SYMPTOMS);

  const [symptomsList, setSymptomsList] = useState(null);
  const [selectedSymptoms, setSelectedSymptoms] = useState([]);

  /**
   * Check current patch state on page load.
   * It's possible the device state has changed and identifying symptoms is not allowed (e.g. device is offline).
   */
  useEffect(() => {
    if (
      deviceDetails.currentPatchState === PATCH_STATUS.RETURN_DEVICE ||
      deviceDetails.currentPatchState === PATCH_STATUS.NO_ACTIVE_DEVICE ||
      deviceDetails.currentPatchState === PATCH_STATUS.PENDING_TRANSMISSION ||
      deviceDetails.activationStatus === PATCH_ACTIVATION_STATUS.Deactivated
    ) {
      navigate(
        `${APP_ROUTES.ROOT}`
      );
    }
  })

  const successMessage = {
      type: "success",
      messageText: "Your symptoms have been recorded",
      nextRoute: `${APP_ROUTES.ROOT}`,
  };

  const failureMessage = {
    type: "failure",
    messageText: "Unable to send out your symptoms",
    nextRoute: `${APP_ROUTES.ROOT}`,
  };

  const captureSymptoms = symCode => {
    if (selectedSymptoms.includes(symCode)) {
      // remove from list
      const orgSymptomArray = [...selectedSymptoms];

      for (let del = 0; del < orgSymptomArray.length; del++) {
        if (orgSymptomArray[del] === symCode) {
          orgSymptomArray.splice(del, 1);
          break;
        }
      }
      setSelectedSymptoms(() => orgSymptomArray);
    } else {
      // add to list
      setSelectedSymptoms([...selectedSymptoms, symCode]);
    }
  };

  useEffect(() => {
    data().then(res => {
      setSymptomsList(
        res?.data?.getSymptoms?.sort((a, b) => a.displayOrder - b.displayOrder)
      );
    });
  }, []);

  const recordSymptoms = () => {
    const input = {
      input: {
        deviceId: device?.deviceId,
        symptomNames: selectedSymptoms,
      },
    };
    apiRecordSymptoms({ variables: input }).then(res => {
      res.data.recordSymptoms
        ? navigate(`/${APP_ROUTES.MESSAGE}`, { state: successMessage })
        : navigate(`/${APP_ROUTES.MESSAGE}`, { state: failureMessage });
    }).catch(err => {
      console.error("Failed [recordSymptoms]:", err);
      navigate(`/${APP_ROUTES.MESSAGE}`, { state: failureMessage })
    });
  };

  return (
    <Grid padding="10px">
      <Grid item className="sticky-header" sx={{ top: "10px" }}>
        <Button onClick={() => navigate(-1)} style={{ paddingTop: "24px" }}>
          <BIcon
            icon={IconLeftArrow}
            style={{ height: "24px", width: "24px" }}
          />
        </Button>
        <Grid item padding="24px 20px" display="flex">
          <Box className="rec-symp-step-counter">2</Box>
          <Box className="rec-symp-step-title">Select your Symptoms</Box>
        </Grid>
      </Grid>
      {gettingSymptomsInProgress?.loading ? (
        <div
          style={{
            color: "#0C80A1",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress color="inherit" />
        </div>
      ) : symptomsList && symptomsList.length > 0 ? (
        <>
          <div className="patient-symptoms-main">
            <header className="patient-symptoms-container">
              {symptomsList.map((dataItem, symptomIndex) => {
                return (
                  <Button
                    key={symptomIndex}
                    style={{ display: "flex", alignItems: "center" }}
                    className={
                      selectedSymptoms.includes(dataItem.name)
                        ? "selected"
                        : "notselected"
                    }
                    onClick={() => {
                      captureSymptoms(dataItem.name);
                    }}
                  >
                    {selectedSymptoms.includes(dataItem.name) ? (
                      <>
                        <BIcon
                          icon={ICONS["IconCheck"]}
                          style={{ margin: "0px", display: "flex" }}
                        />
                      </>
                    ) : null}
                    {dataItem.displayName}
                  </Button>
                );
              })}
            </header>
          </div>
          <div style={{ display: "flex", justifyContent: "center", bottom: "10px"}}  className="sticky-header">
            <Button
              id={generateID("btn", "patientsymptoms", "record")}
              disabled={selectedSymptoms.length === 0 || recordingSymptomsInProgress?.loading}
              variant="outlined"
              sx={{ width: "80%", margin: "16px 0" }}
              className="baxi-button baxi-primary-button"
              onClick={() => {
                recordSymptoms();
              }}
            >
              { 
                recordingSymptomsInProgress?.loading ?
                  <CircularProgress size={30} color="inherit" /> : 
                  messages.recordSymptoms.btnRecordSymptoms 
              }
            </Button>
          </div>
        </>
      ) : (
        <p style={{ marginLeft: "24px" }}>
          {messages.recordSymptoms.txtNoSymptomsAvailable}
        </p>
      )}
    </Grid>
  );
};

export default PatientSymptoms;
