import { useMutation } from "@apollo/client";
import { createContext, useContext, useEffect, useState } from "react";
import { Q_DEVICE_STATUS } from "./../../data/queries/device";
import { CircularProgress } from "@mui/material";
import { useLocation } from "react-router";
import Config, { IConfig } from "../../types/Config";
import PatientDetails, { IPatientDetails } from "../../types/IPatientDetails";
import { calculateCurrentDeviceState } from "../utilityFunctions";
import { PATCH_STATUS } from "../../constants/patchStatus";
import { PATCH_ACTIVATION_STATUS } from "../../constants/patchActivationStatus";

export interface IDeviceDetails {
  activationStatus: PATCH_ACTIVATION_STATUS | null,
  batteryLevelMessage: string;
  batteryLevelPercentage: number;
  dataTransmissionMessage: string;
  deviceType: string;
  isBatteryLevelCritical: boolean;
  isDataTransmissionCritical: boolean;
  lastTransmissionTimestamp: string;
  patientPreferredName: string;
  prescribedWearTimeDays: number;
  recordingStartedAt: string;
  fetchPatchStatus: () => Promise<void>;
  currentPatchState: PATCH_STATUS | null;
}

export const emptyDeviceDetails: IDeviceDetails = {
  activationStatus: null,
  batteryLevelMessage: "",
  batteryLevelPercentage: -1,
  dataTransmissionMessage: "",
  deviceType: "",
  isBatteryLevelCritical: false,
  isDataTransmissionCritical: false,
  lastTransmissionTimestamp: "",
  patientPreferredName: "",
  prescribedWearTimeDays: -1,
  recordingStartedAt: "",
  fetchPatchStatus: null,
  currentPatchState: null,
};

export const DeviceStatus = createContext<IDeviceDetails>(emptyDeviceDetails);

const DeviceDetailsContext = ({ children }) => {
  const [deviceDetails, setDeviceDetails] = useState(emptyDeviceDetails);
  const [getDeviceStatus, { loading, error }] = useMutation(Q_DEVICE_STATUS);
  const config = useContext<IConfig>(Config);
  const device = useContext<IPatientDetails>(PatientDetails);
  const location = useLocation();

  const fetchPatchStatus = async () => {
    if (device.deviceId) {
      try {
        const res = await getDeviceStatus({
          variables: { deviceId: device.deviceId },
        });
        if (res?.data?.getDeviceStatus) {
          const details = res?.data?.getDeviceStatus as IDeviceDetails;
          const newDeviceDetails = { ...deviceDetails, ...details };
          newDeviceDetails.currentPatchState = calculateCurrentDeviceState(
            device,
            newDeviceDetails
          );
          setDeviceDetails(newDeviceDetails);
        }
      } catch (err) {
        console.error("Failed [getDeviceStatus]", err);
      }
    }
  };

  useEffect(() => {
    (async () => await fetchPatchStatus())();
  }, [device]);

  useEffect(
    () =>
      setDeviceDetails(prev => {
        prev.fetchPatchStatus = fetchPatchStatus;
        return { ...prev };
      }),
    [device]
  );

  useEffect(
    () =>
      setDeviceDetails(prev => {
        prev.currentPatchState = calculateCurrentDeviceState();
        return { ...prev };
      }),
    []
  );

  useEffect(() => {
    const pollingInteval = !deviceDetails.recordingStartedAt
      ? config.fastDeviceStatusPollingIntervalMs
      : config.slowDeviceStatusPollingIntervalMs;

    const intervaltId = setInterval(
      async () => await fetchPatchStatus(),
      pollingInteval
    );

    return () => clearTimeout(intervaltId);
  }, [location, deviceDetails, device]);

  return (
    <DeviceStatus.Provider value={deviceDetails}>
      {loading || error ? (
        <>
          <div className="page-header"></div>
          <div
            style={{
              color: "#0C80A1",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            {loading ? <CircularProgress /> : null}
            {error ? "Sorry, an error has occurred" : null}
          </div>
        </>
      ) : (
        children
      )}
    </DeviceStatus.Provider>
  );
};

export default DeviceDetailsContext;
