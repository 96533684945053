import moment from "moment";
import { IPatientDetails } from "../types/IPatientDetails";
import { IDeviceDetails } from "./shared/DeviceDetailsContext";
import SessionStorageUtility from "./utilities/SessionStorageUtility";
import { FORCED_APP_STATE } from "../constants/forcedAppStates";
import { PATCH_STATUS } from "../constants/patchStatus";
import { PATCH_ACTIVATION_STATUS } from "../constants/patchActivationStatus";

// date formatting logic
export const getPrettyDate = (inputDate, format, local = false) => {
  if (!inputDate || !format) return "Error";
  let formattedDate;
  try {
    // TODO implementation per time zone
    const dateObj = new Date(Number(inputDate)).toISOString();
    if (local) {
      // current timezone
      formattedDate = moment.utc(dateObj).local().format(format); // local will convert to the Users system time.
    } else {
      formattedDate = moment.utc(dateObj).format(format); // local will convert to the Users system time.
    }
  } catch (error) {
    console.log("Error in Utility functtion: ", error);
    return "Error";
  }
  return formattedDate;
};

export const convertUTCToDate = (timeStamp: Date) => {
  let formattedDate = "";
  const date = new Date(Number(timeStamp));
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  if (date.toDateString() === today.toDateString()) {
    formattedDate = "Today";
  } else if (date.toDateString() === yesterday.toDateString()) {
    formattedDate = "Yesterday";
  } else {
    formattedDate = getPrettyDate(timeStamp, "dddd, MMM D, YYYY", true);
  }
  return formattedDate;
};

export const generateID = (blockType, module, modifier) => {
  return `${blockType}__${module}__${modifier}`;
};

export const wrapWordsInBold = (text: string, words: string[]) => {
  let out = text;
  words.map(str => {
    out = out.replace(str, `<b>${str}</b>`);
  });
  return {
    __html: out,
  };
};

export const calculateWearTimeRemaining = (
  recordingStartedAt,
  prescribedWearTimeDays
) => {
  const mStart = moment(+recordingStartedAt);
  const mEnd = mStart.clone().add(+prescribedWearTimeDays, "days");
  const now = moment();
  const diffInDays = Math.ceil(moment.duration(mEnd.diff(now)).asDays());

  return diffInDays > 0 ? diffInDays : 0;
};

export const calculateCurrentDeviceState = (patientDetails?: IPatientDetails, deviceDetails?: IDeviceDetails) => {
  const forcedState = SessionStorageUtility.getForcedAppState();
  
  // Highest precedence state
  if (forcedState && forcedState === FORCED_APP_STATE.NO_ACTIVE_DEVICE) {
    return PATCH_STATUS.NO_ACTIVE_DEVICE;
  }

  // Second highest precedence state
  if (
    deviceDetails &&
    deviceDetails.activationStatus === PATCH_ACTIVATION_STATUS.Deactivated
  ) {
    return PATCH_STATUS.RETURN_DEVICE;
  }

  // Third highest precedence state  
  if (forcedState && forcedState === FORCED_APP_STATE.NO_TRANSMISSION) {
    return PATCH_STATUS.NO_TRANSMISSION;
  }
  
  if (!patientDetails && !deviceDetails) {
    return;
  }

  const { recordingStartedAt, prescribedWearTimeDays, activationStatus } = deviceDetails;

  if (activationStatus === PATCH_ACTIVATION_STATUS.Deactivated) {
    return PATCH_STATUS.RETURN_DEVICE;
  }

  if (
    !recordingStartedAt ||
    activationStatus === PATCH_ACTIVATION_STATUS.Pending
  ) {      
    return PATCH_STATUS.PENDING_TRANSMISSION;
  }
  
  const daysLeft = calculateWearTimeRemaining(
    recordingStartedAt,
    prescribedWearTimeDays
  );

  if (patientDetails.deviceId && daysLeft < 1) {
      return PATCH_STATUS.RETURN_DEVICE;
  }

  const { isBatteryLevelCritical, isDataTransmissionCritical } = deviceDetails;

  if (
    patientDetails.deviceId &&
    !patientDetails.mappedToPatient &&
    !SessionStorageUtility.getStoredUnmappedDeviceId()
  ) {
      return PATCH_STATUS.NO_ACTIVE_DEVICE;
  }
  if (!isBatteryLevelCritical && !isDataTransmissionCritical) {
      return PATCH_STATUS.ACTIVE_MONITORING;
  } else if (isDataTransmissionCritical) {
      return PATCH_STATUS.NO_TRANSMISSION;
  } else if (isBatteryLevelCritical) {
      return PATCH_STATUS.SWITCH_PATCH;
  }
};
