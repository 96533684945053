import React from "react";

export interface IConfig {
  apiEndpointUrl: string;
  fastDeviceStatusPollingIntervalMs: number;
  slowDeviceStatusPollingIntervalMs: number;
  b2cPolicies: {
    names: {
      signUpSignIn: string;
      passwordChange: string;
    };
    authorities: {
      signUpSignIn: {
        authority: string;
      };
      passwordChange: {
        authority: string;
      };
    };
    clientId: string;
    authorityDomain: string;
    redirectUri: string;
  };
}

const defaultConfig: IConfig = {
  apiEndpointUrl: "",
  fastDeviceStatusPollingIntervalMs: Infinity,
  slowDeviceStatusPollingIntervalMs: Infinity,
  b2cPolicies: {
    names: {
      signUpSignIn: "",
      passwordChange: "",
    },
    authorities: {
      signUpSignIn: {
        authority: "",
      },
      passwordChange: {
        authority: "",
      },
    },
    clientId: "",
    authorityDomain: "",
    redirectUri: "",
  },
};

const Config = React.createContext<IConfig>(defaultConfig);

export default Config;
