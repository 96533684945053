import { Route, Routes } from "react-router-dom";
import HomeComponent from "../HomeComponent";
import LearnComponent from "../LearnComponent";
import Main from "../Main";
import MessageComponent from "../MessageComponent";
import MyInfoComponent from "../MyInfoComponent";
import PatientSymptoms from "../PatientSymptoms";
import PatientSymptomsHistoryComponent from "../PatientSymptomsHistoryComponent";
import ClearDeviceIdComponent from "../ClearDeviceIdComponent";
import { APP_ROUTES } from "../../constants/appRoutes";
import OfflineComponent from "../OfflineComponent";
import HowToReturnDevice from "../HowToReturnDeviceComponent";
import TechSupport from "../TechSupportComponent";
import DataPolicy from "../DataPolicyComponent";
import TermsOfUse from "../TermsOfUseComponent";
import BuildInfo from "../BuildInfoComponent";
import DeviceInfo from "../DeviceInfoComponent";
import QuickStartComponent from "../QuickStartComponent";
import Tips from "../TipsComponent";
import VideoInstructions from "../VideoInstructionsComponent";
import ForceAppStateComponent from "../ForceAppStateComponent";
import ChestPressButton from "../ChestPressButton";
const RouteComponent = () => {
  return (
    <Routes>
      <Route path={APP_ROUTES.ROOT} element={<Main />}>
        <Route index element={<HomeComponent />} />
        <Route path={APP_ROUTES.HISTORY} element={<PatientSymptomsHistoryComponent />} />
        <Route path={APP_ROUTES.MY_INFO} element={<MyInfoComponent />} />
        <Route path={`${APP_ROUTES.MY_INFO}/${APP_ROUTES.HOW_TO_RETURN_DEVICE}`} element={<HowToReturnDevice />} />
        <Route path={`${APP_ROUTES.MY_INFO}/${APP_ROUTES.TECH_SUPPORT}`} element={<TechSupport />} />
        <Route path={`${APP_ROUTES.MY_INFO}/${APP_ROUTES.DATA_POLICY}`} element={<DataPolicy />} />
        <Route path={`${APP_ROUTES.MY_INFO}/${APP_ROUTES.TERMS_OF_USE}`} element={<TermsOfUse />} />
        <Route path={`${APP_ROUTES.MY_INFO}/${APP_ROUTES.BUILD_INFO}`} element={<BuildInfo />} />
        <Route path={`${APP_ROUTES.MY_INFO}/${APP_ROUTES.DEVICE_INFO}`} element={<DeviceInfo />} />
        <Route path={APP_ROUTES.LEARN} element={<LearnComponent />} />
        <Route path={`${APP_ROUTES.LEARN}/${APP_ROUTES.TECH_SUPPORT}`} element={<TechSupport />} />
        <Route path={`${APP_ROUTES.LEARN}/${APP_ROUTES.QUICK_START}`} element={<QuickStartComponent />} />
        <Route path={`${APP_ROUTES.LEARN}/${APP_ROUTES.IMPORTANT_TIPS}`} element={<Tips />} />
        <Route path={`${APP_ROUTES.LEARN}/${APP_ROUTES.VIDEO_INSTRUCTIONS}`} element={<VideoInstructions />} />
        <Route path={APP_ROUTES.PATIENT_SYMPTOMS} element={<PatientSymptoms />} />
        <Route path={APP_ROUTES.PRESS_CHEST_BUTTON} element={<ChestPressButton />} />
        <Route path={APP_ROUTES.OFFLINE} element={<OfflineComponent />} />
        <Route path={APP_ROUTES.MESSAGE} element={<MessageComponent />} />
        <Route path={APP_ROUTES.CLEAR_STORED_DEVICE_ID} element={<ClearDeviceIdComponent />} />
        <Route path={APP_ROUTES.FORCE_APP_STATE} element={<ForceAppStateComponent />} />
        <Route path="*" element={<>Nothing here</>} />
      </Route>
    </Routes>
  );
};

export default RouteComponent;
