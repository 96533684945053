import Config from "../types/Config";
import React, { useEffect } from "react";
import { ApolloProvider } from "@apollo/client";
import { MsalProvider, useMsal } from "@azure/msal-react";
import { ThemeProvider } from "@emotion/react";
import { Paper } from "@mui/material";

import { Client } from "../data/Client";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import RouteComponent from "./routes/RouteComponent";
import useOnlineStatus from "./hooks/useOnlineStatus";
import { useNavigate } from "react-router";
import { APP_ROUTES } from "../constants/appRoutes";
import AppInfo from "../types/AppInfo"
import PullToRefresh from "react-simple-pull-to-refresh";
function App(props: any) {
  const { instance } = useMsal();
  const navigate = useNavigate();

  const client = Client.getInstance(props.config.apiEndpointUrl);
  const isOnline = useOnlineStatus();

  useEffect(() => {
    if (!isOnline) {
      navigate(APP_ROUTES.OFFLINE);
    } else if (location.href.includes(APP_ROUTES.OFFLINE)) {
      navigate(APP_ROUTES.ROOT);
    }
  }, [isOnline]);

  return (
    <AppInfo.Provider value={props.buildVersion}>
      <Config.Provider value={props.config}>
        <ApolloProvider client={client}>
          <ThemeProvider theme={props.theme}>
            <MsalProvider instance={props.instance}>
              <Paper sx={{ display: { mobile: "none", laptop: "block" } }}>
                <Sidebar></Sidebar>
              </Paper>

              <Paper sx={{ display: { mobile: "block", laptop: "none" } }}>
                <Navbar></Navbar>
              </Paper>
              <PullToRefresh onRefresh={() => Promise.resolve().then(() => location.reload())}> 
                <Paper
                  style={{ boxShadow: "none" }}
                  sx={{
                    marginLeft: { mobile: "0px", laptop: "280px" },
                    marginRight: { mobile: "0px", laptop: "40px" },
                    height: "calc(100vh - 90px)",
                    position: { mobile: "relative", laptop: "fixed" },
                    width: { mobile: "100%", laptop: "calc(100vw - 320px)" },
                    overflowY: { mobile: "auto", laptop: "auto" },
                    background: { mobile: "inherit", laptop: "scroll" },
                    overflowX: { mobile: "hidden", laptop: "hidden" },
                  }}
                >
                  <RouteComponent />
                </Paper>
              </PullToRefresh>
            </MsalProvider>
          </ThemeProvider>
        </ApolloProvider>
      </Config.Provider>
    </AppInfo.Provider>
  );
}

export default App;
