export const enum APP_ROUTES {
  "ROOT" = "/",
  "HISTORY" = "history",
  "MY_INFO" = "my-info",
  "LEARN" = "learn",
  "PATIENT_SYMPTOMS" = "patient-symptoms",
  "PRESS_CHEST_BUTTON" = "press-chest-button",
  "OFFLINE" = "offline",
  "MESSAGE" = "message",
  "CLEAR_STORED_DEVICE_ID" = "clearStoredDeviceId",
  "FORCE_APP_STATE" = "forceAppState",
  "CLEAR_FORCED_APP_STATE" = "clearforcedAppState",
  "HOW_TO_RETURN_DEVICE" = "how-to-return-device",
  "TECH_SUPPORT" = "tech-support",
  "DATA_POLICY" = "data-policy",
  "TERMS_OF_USE" = "term-of-use",
  "BUILD_INFO" = "build-info",
  "DEVICE_INFO" = "device-info",
  "IMPORTANT_TIPS" = "important-tips",
  "QUICK_START" = "quick-start",
  "VIDEO_INSTRUCTIONS" = "video-instructions",
}
