import { Button, Grid } from "@mui/material";
import React, { useContext, useState } from "react";
import BIcon from "./Icons/IconComponent";
import { ICONS } from "./Icons/IconComponentMapping";
import { useNavigate } from "react-router-dom";
import PatientDetails, { IPatientDetails } from "../types/IPatientDetails";
import { UnauthenticatedTemplate } from "@azure/msal-react";
import messages from "./../data/UserMessages.json";
import GeneralLoginComponent from "./login/GeneralLoginComponent";
import { DeviceStatus, IDeviceDetails } from "./shared/DeviceDetailsContext";
import {
  generateID,
  wrapWordsInBold,
} from "./utilityFunctions";
import SessionStorageUtility from "./utilities/SessionStorageUtility";
import { PATCH_STATUS } from "../constants/patchStatus";

const HomeComponent = () => {

  const navigate = useNavigate();

  const [hasForcedHomeState, setHasForcedHomeState] = useState(false);

  const device = React.useContext<IPatientDetails>(PatientDetails);
  const deviceDetails = useContext<IDeviceDetails>(DeviceStatus);

  React.useEffect(() => getcurrentHomeState(), [device, deviceDetails]);

  const getcurrentHomeState = () => {
    const forcedState = SessionStorageUtility.getForcedAppState();
    setHasForcedHomeState(!!forcedState);
  };

  const transmissionPending = () => {
    return deviceDetails.currentPatchState === PATCH_STATUS.PENDING_TRANSMISSION;
  }

  const handleHomeScreenAction = async () => {
    /**
     * Get device status from server before navigating (e.g. identifying symptoms).
     * It's possible the device state has changed and identifying symptoms is not allowed (e.g. device is offline).
     */
    if(deviceDetails.currentPatchState === PATCH_STATUS.ACTIVE_MONITORING ||
      deviceDetails.currentPatchState === PATCH_STATUS.SWITCH_PATCH ||
      deviceDetails.currentPatchState === PATCH_STATUS.NO_TRANSMISSION) {
      await deviceDetails.fetchPatchStatus();
    }
    navigate(messages.home[deviceDetails.currentPatchState]?.actionDestination);
  }

  const getActionButtonClassName = () => {
    if (deviceDetails.currentPatchState === PATCH_STATUS.NO_TRANSMISSION) {
      return "baxi-hollow-button";
    }
    return "baxi-primary-button";
  }
  return (
    <>
      {!hasForcedHomeState && !device?.deviceId && !device?.mappedToPatient ? (
        <UnauthenticatedTemplate>
          <GeneralLoginComponent />
        </UnauthenticatedTemplate>
      ) : (
        <Grid
          container
          spacing={0}
          direction="column"
          style={{
            display: "flex",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px",
            overflow: "hidden",
          }}
        >
          <Grid item mobile={12} laptop={6} style={{ background: "white", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
              <Grid
                item
                padding="0 20px 0 20px"
                className="home-welcome-desc"
              >
                {messages.home[deviceDetails.currentPatchState]?.txtHomeHeaderDesc.map(msg => (
                  <div
                    className="desc"
                    dangerouslySetInnerHTML={wrapWordsInBold(
                      msg,
                      messages.general.highlights
                    )}
                  ></div>
                ))}
              </Grid>
              <Grid
                item
                className={transmissionPending() ?  "rotate-element" : ''}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <BIcon
                  icon={ICONS[deviceDetails.currentPatchState]}
                  style={{
                    verticalAlign: "bottom",
                    width: "auto",
                    height: transmissionPending() ? "128px" : "200px",
                  }}
                />
              </Grid>
              <Grid
                item
                className="baxi-button-wrapper"
                style={{ display: "flex"}}
                sx={{
                  paddingLeft: { mobile: "0px", laptop: "20px" },
                  justifyContent: { mobile: "center", laptop: "inherit" },
                }}
              >
                {deviceDetails.currentPatchState === PATCH_STATUS.NO_ACTIVE_DEVICE ||
                deviceDetails.currentPatchState === PATCH_STATUS.PENDING_TRANSMISSION ? null : (
                  <Button
                    id={generateID("btn", "home", "identify-symptoms")}
                    variant="outlined"
                    sx={{ width: "80%" }}
                    className={`baxi-button ${getActionButtonClassName()}`}
                    onClick={handleHomeScreenAction}
                  >
                    {messages.home[deviceDetails.currentPatchState]?.actionBtnTxt}
                  </Button>
                )}
              </Grid>
            </Grid>
        </Grid>
      )}
    </>
  );
};

export default HomeComponent;
