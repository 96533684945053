import { useMutation } from "@apollo/client";
import { Fragment, useContext, useEffect, useState } from "react";
import { M_GET_SYMPTOMS_HISTORY } from "../data/queries/symptoms";
import { convertUTCToDate, getPrettyDate } from "./utilityFunctions";
import CircularProgress from "@mui/material/CircularProgress";
import DeviceDetails, { IPatientDetails } from "../types/IPatientDetails";
import messages from "./../data/UserMessages.json";
import GeneralLoginComponent from "./login/GeneralLoginComponent";
import { Box } from "@mui/material";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

const PatientSymptomsHistoryComponent = () => {

  const device = useContext<IPatientDetails>(DeviceDetails);
  const [dataSymptomsHistory, loading] = useMutation(M_GET_SYMPTOMS_HISTORY);
  const [symptomsHistoryList, setSymptomsHistoryList] = useState([]);

  useEffect(() => {
    if (device && (!device.deviceId || !device.email)) {
      return
    }
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // get Users current timezone
    const input = {
      input: {
        deviceId: device.deviceId,
        timezone: timezone,
      },
    };
    dataSymptomsHistory({ variables: input }).then(res => setSymptomsHistoryList(res.data.readSymptomsHistory));
  }, [dataSymptomsHistory]);

  return (
    <>
      <UnauthenticatedTemplate>
        <GeneralLoginComponent />
      </UnauthenticatedTemplate>

      <AuthenticatedTemplate>
        <Box className="page-header sticky-header">
          History
        </Box>
        {loading?.loading ? (
          <div
            style={{
              color: "#0C80A1",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress color="inherit" />
          </div>
        ) : symptomsHistoryList && symptomsHistoryList.length > 0 ? (
          <div className="patient-symptoms-history">
            {symptomsHistoryList.map((symList, historyIndex) => (
              <div
                style={{
                  width: "100%",
                  background: historyIndex % 2 == 0 ? "#E1F4FA" : "#FFF",
                }}
                key={historyIndex}
              >
                <div style={{ paddingLeft: "24px" }}>
                  <p className="history-date">
                    {convertUTCToDate(symList.date)}
                  </p>
                  <div>
                    {symList.symptomsHistory.map((symptoms, symIndex) => (
                      <Fragment key={`${historyIndex}_${symIndex}`}>
                        <div style={{ display: "inlineFlex" }}>
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="history-time">
                                    {getPrettyDate(
                                      symptoms.timestamp,
                                      "hh:mma",
                                      true
                                    )}
                                  </div>
                                </td>
                                <td>
                                  <div className="history-symptoms-desc">
                                    {symptoms.symptomDisplayNames.join(", ")}
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="landingpage-vertical"></div>
                      </Fragment>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p style={{ marginLeft: "24px" }}>
            {messages.symptomsHistory.txtNoSymptomsRecorded}
          </p>
        )}
      </AuthenticatedTemplate>
    </>
  );
};

export default PatientSymptomsHistoryComponent;
