import gql from "graphql-tag";

/*
  TODO:
  Add following fields after migration to HTTS (BDM API):
  1. prescribedWearTimeDays
  2. recordingStartedAt
*/
export const Q_DEVICE_STATUS = gql`
  mutation GetDeviceStatus($deviceId: String!) {
    getDeviceStatus(deviceId: $deviceId) {
      patientPreferredName
      deviceType
      lastTransmissionTimestamp
      isDataTransmissionCritical
      dataTransmissionMessage
      batteryLevelPercentage
      isBatteryLevelCritical
      batteryLevelMessage
      prescribedWearTimeDays
      recordingStartedAt,
      activationStatus
    }
  }
`;

export const Q_DEVICE_FROM_USER = gql`
  query getDeviceIdFromUser($email: String!) {
    patient(email: $email) {
      devices {
        deviceId
      }
    }
  }
`;
