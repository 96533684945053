import { SvgIcon } from "@mui/material";
import React from "react";

const IconPendingTransmission = (props: { style: React.CSSProperties }) => {
  return (
    <SvgIcon style={props.style} viewBox="0 0 128 128">
      <svg
        width="128"
        height="128"
        viewBox="0 0 128 128"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M62.431 5.84757C62.4616 2.97925 60.1049 0.631896 57.1632 0.600537C56.6846 0.595435 56.2402 0.670382 55.7954 0.779475L55.7958 0.745329C-10.6997 12.4445 0.898175 77.6932 0.898175 77.6932C1.34996 16.6938 48.3271 11.4004 56.7261 10.9435C56.8312 10.9446 56.9358 10.9799 57.0526 10.9811C57.3678 10.9845 57.66 10.9534 57.9524 10.8996C57.9874 10.9 58.0108 10.9003 58.0108 10.9003C60.4903 10.4827 62.4038 8.39719 62.431 5.84757Z"
          fill="#164997"
        />
        <path
          d="M112.061 96.0582C114.437 97.7615 117.759 97.2705 119.497 94.9594C119.78 94.5909 119.983 94.1827 120.14 93.7713L120.175 93.7737C149.139 34.3536 88 5.33331 88 5.33331C138.569 41.41 115.634 81.6648 111.136 88.5671C111.071 88.654 110.985 88.7165 110.921 88.8034C110.74 89.042 110.594 89.3058 110.459 89.559C110.434 89.5915 110.421 89.602 110.421 89.602C109.322 91.8085 109.954 94.5445 112.062 96.0469L112.061 96.0582Z"
          fill="#164997"
        />
        <path
          d="M11.2846 89.9908C8.59087 91.1311 7.37293 94.1904 8.55623 96.8126C8.74932 97.232 9.00498 97.612 9.28724 97.9602L9.25015 97.9794C47.5558 152.445 103.764 115.55 103.764 115.55C46.5737 139.971 22.2789 100.276 18.3908 92.9822C18.3533 92.8869 18.3402 92.7827 18.291 92.6863C18.1658 92.4108 18.0151 92.1557 17.84 91.9096C17.8202 91.8733 17.8097 91.8608 17.8097 91.8608C16.3927 89.8217 13.6606 88.9793 11.273 89.9897L11.2846 89.9908Z"
          fill="#164997"
        />
      </svg>
    </SvgIcon>
  );
};

export default IconPendingTransmission;
